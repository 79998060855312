$(document).ready(() => {
  var swiper = new Swiper(".swiper-reassurance", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:1,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
      992: {
        slidesPerView: 2,
        slidesPerGroup: 1,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      }
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });
  
  
  var swiper = new Swiper(".swiper-products", {
    slidesPerView: "3",
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  

  var swiper = new Swiper(".swiper-viewed-products", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-viewed',
      prevEl: '.swiper-button-prev-viewed',
    }
  });
  

  var swiper = new Swiper(".swiper-products-accessories", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-accessories',
      prevEl: '.swiper-button-prev-accessories',
    }
  });
  

  var swiper = new Swiper(".swiper-products-crosssell", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      767: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1024: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-products-crosssell',
      prevEl: '.swiper-button-prev-products-crosssell',
    }
  });


  var swiper = new Swiper(".swiper-products-groups-1", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-1',
      prevEl: '.swiper-button-prev-1',
    }
  });
  

  var swiper = new Swiper(".swiper-products-groups-2", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-2',
      prevEl: '.swiper-button-prev-2',
    }
  });
  

  var swiper = new Swiper(".swiper-products-groups-3", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-3',
      prevEl: '.swiper-button-prev-3',
    }
  });
  

  var swiper = new Swiper(".swiper-products-groups-4", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-4',
      prevEl: '.swiper-button-prev-4',
    }
  });


  var swiper = new Swiper(".swiper-new-products", {
    slidesPerView: "auto",
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  

  var swiper = new Swiper(".swiper-thumbs", {
    slidesPerView: "auto",
    navigation: {
      nextEl: '.swiper-button-next-thumbs',
      prevEl: '.swiper-button-prev-thumbs',
    },
  });
  

  var swiper = new Swiper(".swiper-last-blog", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    }
  });
  

  var swiper = new Swiper(".swiper-prestablog-products", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-prestablog-products',
      prevEl: '.swiper-button-prev-prestablog-products',
    }
  });
  

  var swiper = new Swiper(".swiper-prestablog-articles", {
    slidesPerView: 1,
    slidesPerGroup:1,
    watchOverflow: true,
    breakpoints: {
      576: {
        slidesPerView: 2,
        slidesPerGroup:2,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next-prestablog-articles',
      prevEl: '.swiper-button-prev-prestablog-articles',
    }
  });

});
