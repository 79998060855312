$(document).ready(() => {
	var viewportWidth = $(window).width();

	var headerHeight = $("#header").height();
	var headerTopHeight = $("#header .header-top .container-fluid").height();
	var headerBottomHeight = $("#header .header-top .container-full").height();
	var headerHeightScroll = headerHeight - headerBottomHeight;

	var productThumbnailsHeight = $(".product__images__container").height();
	var productColHeight = $(".product__col").height();
	var productDiffHeight = productThumbnailsHeight - productColHeight;

	$(window).scroll(function() {

		if (!$('body').is('#product') && !$('body').is('#checkout') ) {
	
			if (viewportWidth <= 991) {

			    var position = $(window).scrollTop(); 

				$(window).scroll(function() {
				    var scroll = $(window).scrollTop();

				    if(scroll > position) {
				    	// Actions when scrolling down
						$("#wrapper").css("padding-top",0);
						$("#header").removeClass("scrolling");
						setTimeout(() => {$("#header").removeClass("scrolling-fixed");}, 1000);
				    } else {
				    	// Actions when scrolling up
						$("#wrapper").css("padding-top",headerHeight);
						$("#header").addClass("scrolling");
						setTimeout(() => {$("#header").addClass("scrolling-fixed");}, 1000);
				    }
				    position = scroll;
				});

			} else {
				/* HEADER SCROLL */
				if ($(window).scrollTop() > 250) {
					$("#wrapper").css("padding-top",headerHeight);
					$("#header").addClass("scrolling");
				}
				if ($(window).scrollTop() > 300) {
					$("#header").addClass("scrolling-fixed");
				}
				if ($(window).scrollTop() < 250) {
					$("#wrapper").css("padding-top",0);
					$("#header").removeClass("scrolling");
				}
				if ($(window).scrollTop() < 300) {
					$("#header").removeClass("scrolling-fixed");
				}
			}			
		}

		if ($('body').is('#product')) {

			// Check if products tabs nabar has reached the top of the viewport and class to fix it
		    var $divTarget = $('.nav-tabs');
		    var divTargeToTop = $(".navTabs").offset().top;
		    var navTabHeight = $divTarget.height();
		    
			$(window).scroll(function () {
			    var position = $(window).scrollTop();

			    if (position >= divTargeToTop) {
			        $(".navTabs").css("min-height", navTabHeight);
			        $divTarget.addClass('fixed');
			    } else {
			        $(".navTabs").css("min-height", 0);
			        $divTarget.removeClass('fixed');
			    }
			});

			// Follow product tabs when is active
			$('body#product').scrollspy({ target: '.nav-tabs' });
		}
	});


	// add product page youtube top video src to modal + event after product update
	var $videoSrc = "";  
	var $videoSrcReset = "";  

	function setupVideoModal() {
	    // Handle video modal button click
	    $('.video-modal-btn').click(function() {
	        $videoSrc = $(this).data("src");
	        $("#video-modal-iframe").attr('src', $videoSrc + "?modestbranding=1&amp;showinfo=0");
	    });

	    // Close modal when clicking on close button
	    $('#video-modal .btn-close').click(function() {
	        $videoSrcReset = "";
	        $('#video-modal-iframe').attr('src', '');
	    });

	    // Close modal when clicking on the backdrop
	    $('#video-modal').on('click', function(event) {
	        if ($(event.target).is('#video-modal')) {
		        $('#video-modal-iframe').attr('src', '');
		        $('#video-modal').hide();
	        }
	    });
	}

	// Inmitial call
	setupVideoModal();

	// Reinitialize the video modal events after product update
	prestashop.on('updatedProduct', function(resp) {
	    setupVideoModal();

		var swiper = new Swiper(".swiper-thumbs", {
		    slidesPerView: "auto",
		    navigation: {
		    	nextEl: '.swiper-button-next-thumbs',
		    	prevEl: '.swiper-button-prev-thumbs',
		    },
		});
	});


    /* SHOW SEARCHBAR */
	$(".search-icon").click(function() {
		$("#search_widget form").toggleClass("show");
	})


	/* CLOSE MENU MOBILE SUBMENUS WHEN CLOSING MENU */
	$(".offcanvas-header .btn-close").on('click', function () {
        $("#adtm_menu ul#menu li.li-niveau1").removeClass('adtm_sub_open');
        $("#adtm_menu ul#menu li div.adtm_sub").removeClass('adtm_submenu_toggle_open');
	});

	$('.advtm_menu_close').on('click', function () {
        $("#adtm_menu ul#menu li.li-niveau1").removeClass('adtm_sub_open');
        $("#adtm_menu ul#menu li div.adtm_sub").removeClass('adtm_submenu_toggle_open');
	});


	/* HOMEPAGE COLLECTIONS SWIPER SLIDER */
    $('.w-15.collections.cb-wrapper .cb-item').addClass('swiper-slide');
    $('.w-15.collections.cb-wrapper').append('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>');

    var $collectionsChildren = $('.w-15.collections.cb-wrapper').children('.cb-item');
    var collectionsWrapper = $('<div class="swiper-wrapper"></div>');
    $collectionsChildren.wrapAll(collectionsWrapper);

	var swiper = new Swiper(".w-15.collections", {
		slidesPerView: "auto",
		centeredSlides: true,
		loop: true,
	    navigation: {
	    	nextEl: '.swiper-button-next',
	    	prevEl: '.swiper-button-prev',
	    },
	});

    $('blockquote').each(function() {
        if ($(this).find('img').length > 0) {
            $(this).addClass('blockquoteImg')
        }
    });


    $('.page-content table').each(function() {
        $(this).wrap('<div class="table-responsive"></div>');
    });


    // CMS PAGE TOGGLE
    $('.faq .q').on('click',function(){
    	$(this).toggleClass('active').next('.a').stop(true).slideToggle();
    }).css('cursor','pointer');
    $('.faq .a').first().slideDown(0).prev('.q').addClass('active');
    

    // IFRAME VIDEOS RATIO IF NOT CREATIVE ELEMENT
    $('.page-cms iframe').each(function() {
	    if (!$(this).parent().hasClass('elementor-custom-embed')) {
	        var wrapper = $('<div class="ratio ratio-16x9"></div>');
	        $(this).wrap(wrapper);
	    }
	});

    // CHANGE THE REQUIRED FIELDS MARKER
    if ($('.ets_cfu_span').length) {
	    $('.ets_cfu_span').each(function () {
	        let content = $(this).html();
	        content = content.replace('*', '<span class="text-red">*</span>');
	        $(this).html(content);
	    });
	}

	// FANCYBOX OLD IMG CALL
	$('#prestablogfront a.fancy_image').fancybox();

});
